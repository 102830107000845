import ContentBannerComponent from "../components/ContentBanner/ContentBanner.component";
import React, {useContext, useRef, useState} from "react";
import {Alert, Banner, Button, Card, FloatingLabel, Label, Textarea, ToggleSwitch} from "flowbite-react";
import {useTranslation} from "react-i18next";
import {AppContext, AppOrder, AppOrderCustomer} from "../App.context";
import {FaAngleLeft, FaRegFaceSadTear} from "react-icons/fa6";
import {convertArrivalDate, convertDepartureDate, diffInDays, minArrivalDate} from "../lib/utils";
import {AppRoom} from "../app.data";
import {NavLink, unstable_useViewTransitionState} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import {Validations} from "../lib/validations";
import {PiSealWarningDuotone} from "react-icons/pi";
import {ApiBookingService, OrderCustomer, OrderRoom} from "../lib/api-booking.service";
import {IoMdHappy} from "react-icons/io";
import {PacmanLoader} from "react-spinners";

interface OrderedRoom extends AppRoom {
    amount: number;
    price: number;
}

export default function BookingAcceptPage() {
    const {t} = useTranslation();
    const {language, roomsInfo, order, changeOrderCustomer, clearOrder} = useContext(AppContext);
    const {register, handleSubmit, setError, formState: {errors, isSubmitted, isValid}} = useForm({reValidateMode: "onChange", mode: "onChange"});

    const orderedRooms = order.rooms.map( r => {
        const _room = roomsInfo.find( _r => _r.externalId === r.categoryId.toString()) as OrderedRoom | undefined;
        if (_room) {
            _room.amount = r.amount;
            _room.price = r.price;

            return _room;
        }

        return undefined;
    }).filter(r => r !== undefined) as OrderedRoom[];
    const dateFrom = convertArrivalDate(new Date(order.arrivalDate));
    const dateTo = convertDepartureDate(new Date(order.departureDate));
    const scrollToTop = useRef(false);
    const to = `/book/accept`;

    if (!unstable_useViewTransitionState(to) && !scrollToTop.current) {
        scrollToTop.current = true;
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const total = () => {
        let sum = 0;
        orderedRooms.forEach( r => sum+= r.amount * r.price);
        return sum;
    }

    const isValidDates = (order: AppOrder): boolean => {
        if (!order || !order.arrivalDate) return false;

        return new Date(order.arrivalDate) >= convertArrivalDate(minArrivalDate);
    }

    const isValidRooms = (order: AppOrder): boolean => {
        if (!order || !order.rooms) return false;

        return order.rooms.length > 0;
    }

    const canAccept = (order: AppOrder): boolean => {
        return isValidDates(order) && isValidRooms(order);
    }

    const [state, setState] = useState('init' as 'init' | 'accepting' | 'success' | 'error');
    const [saveCustomer, setSaveCustomer] = useState(true);

    const accept: SubmitHandler<any> = (data) => {
        const customer: AppOrderCustomer = {fio: data.fio, email: data.email, number: data.number, remarks: data.remarks};
        if (saveCustomer) changeOrderCustomer(customer);

        if (!isValidRooms(order)) {
            setState('error');
            setError('request', {type: 'rooms'})
        }

        if (!isValidDates(order)) {
            setState('error');
            setError('request', {type: 'dates'})
        }

        const orderCustomer: OrderCustomer = {name: customer.fio, email: customer.email, telephone: customer.number, remarks: customer.remarks};
        const orderRooms: OrderRoom[] = [];
        const arrivalDate = new Date(order.arrivalDate);
        const departureDate = new Date(order.departureDate);

        for (const room of order.rooms) {
            for (let i = 0; i < room.amount; i++) {
                orderRooms.push({categoryId: parseInt(room.categoryId), arrival: arrivalDate.getTime(), departure: departureDate.getTime(), invoice: room.price} as OrderRoom)
            }
        }

        setState("accepting");

        ApiBookingService.order({customer: orderCustomer, rooms: orderRooms}).then( res => {
            if (res.status === 200) {
                setState("success");
                clearOrder(saveCustomer);
            } else {
                setState("error");
            }

        }).catch( res => {
            setState("error");
            console.log(res)
        });
    }

    return (
        <>
            <ContentBannerComponent/>

            <div
                className={'container flex flex-col shadow-xl shadow-black dark:shadow-gray-400 self-center bg-light-container-bg dark:bg-dark-container-bg dark:text-white'}>

                <div className={'flex flex-row w-full lg:w-4/5 self-center py-6 sm:py-12 md:py-16'}>
                    <NavLink className={'flex flex-row items-center'} to={'/book'} unstable_viewTransition={true}>
                        <FaAngleLeft className={'text-light-main dark:text-white h-5 w-5 md:h-10 md:w-10 '}/>
                        <p className={'text-xs md:text-sm lg:text-lg'}>{t('button.back')}</p>
                    </NavLink>

                    <div
                        className={'flex flex-col flex-grow-1 basis-full text-center items-center text-lg md:text-2xl lg:text-3xl justify-center'}>
                        {t('header.accept_booking')}
                    </div>
                </div>

                { state === 'error' && (
                    <div className={'flex flex-col w-full lg:w-4/5 self-center py-6 px-6 lg:px-0'}>
                        <Alert
                            color={'failure'}
                            icon={FaRegFaceSadTear}
                            rounded
                        >
                            {t('alert.create_book_error')}
                        </Alert>

                    </div>
                )}

                { state === 'success' && (
                    <div className={'flex flex-col w-full lg:w-4/5 self-center py-6 px-6 lg:px-0'}>
                        <Alert
                            color={'success'}
                            icon={IoMdHappy}
                            rounded
                        >
                            {t('alert.create_book_success', {email: order.customer?.email })}
                        </Alert>

                        <NavLink className={'flex flex-row justify-center pt-6'} to={'/'} unstable_viewTransition={true}>
                            <Button
                                className={'flex basis-full sm:basis-1/3'}
                                color={'default'}
                                size={'lg'}
                            >
                                {t('button.main')}
                            </Button>
                        </NavLink>
                    </div>
                )}

                {(!isValidDates(order) || errors.request?.type === 'dates') && (
                    <div className={'flex flex-col w-full lg:w-4/5 self-center py-6 px-6 lg:px-0'}>
                        <Alert
                            color={'warning'}
                            icon={PiSealWarningDuotone}
                            rounded
                        >
                            {t('alert.wrong_dates')}
                        </Alert>

                    </div>
                )}

                {((!isValidRooms(order) || errors.request?.type === 'rooms') && state !== 'success') && (
                    <div className={'flex flex-col w-full lg:w-4/5 self-center py-6 px-6 lg:px-0'}>
                        <Alert
                            color={'warning'}
                            icon={PiSealWarningDuotone}
                            rounded
                        >
                            {t('alert.nothing_chosen')}
                        </Alert>

                    </div>
                )}

                {state !== 'success' && (
                    <div className={'flex flex-row w-11/12 lg:w-4/5 self-center'}>
                        <div className={'flex flex-col lg:flex-row basis-full gap-6 py-6 '}>

                            <div className={'flex flex-col basis-2/3 gap-4'}>

                                <Card className={'flex flex-col'}>
                                    <div className={'flex flex-col basis-full py-4 px-4'}>
                                        <h1 className={'py-4'}>{t('customer.info')}: </h1>

                                        <FloatingLabel
                                            label={t('customer.fio') + ' *'}
                                            variant={'outlined'}
                                            required

                                            {...register('fio', {
                                                required: {
                                                    message: t('validations.required', {field: t('customer.fio')}),
                                                    value: true
                                                },
                                                validate: {
                                                    minLength: v => Validations.minTrimLength(t('customer.fio'), v, 3, t),
                                                    maxLength: v => Validations.maxTrimLength(t('customer.fio'), v, 255, t),
                                                    text: v => Validations.text(t('customer.fio'), v, t, false),
                                                }
                                            })}
                                            defaultValue={order.customer?.fio}
                                            color={errors.fio ? 'error' : 'default'}
                                            helperText={errors.fio ? errors.fio.message as string : undefined}
                                            disabled={state === 'accepting'}
                                        />

                                        <FloatingLabel
                                            label={t('customer.email') + ' *'}
                                            variant={'outlined'}
                                            required

                                            {...register('email', {
                                                required: {
                                                    message: t('validations.required', {field: t('customer.email')}),
                                                    value: true
                                                },
                                                validate: {
                                                    format: v => Validations.email(v, t),
                                                }
                                            })}
                                            defaultValue={order.customer?.email}
                                            color={errors.email ? 'error' : 'default'}
                                            helperText={errors.email ? errors.email.message as string : undefined}
                                            disabled={state === 'accepting'}
                                        />

                                        <FloatingLabel
                                            label={t('customer.number') + ' *'}
                                            variant={'outlined'}
                                            required

                                            {...register('number', {
                                                required: {
                                                    message: t('validations.required', {field: t('customer.number')}),
                                                    value: true
                                                },
                                                validate: {
                                                    format: v => Validations.phoneValidation(v, t),
                                                }
                                            })}
                                            defaultValue={order.customer?.number}
                                            color={errors.number ? 'error' : 'default'}
                                            helperText={errors.number ? errors.number.message as string : undefined}
                                            disabled={state === 'accepting'}
                                        />



                                        <Label
                                            className={'text-gray-500 dark:text-gray-400'}>{t('customer.remarks')}</Label>
                                        <Textarea
                                            rows={5}
                                            {...register('remarks', {
                                                validate: {
                                                    minLength: v => Validations.minTrimLength(t('customer.remarks'), v, 3, t),
                                                    maxLength: v => Validations.maxTrimLength(t('customer.remarks'), v, 500, t),
                                                    text: v => Validations.text(t('customer.remarks'), v, t),
                                                }
                                            })}
                                            disabled={state === 'accepting'}
                                            defaultValue={order.customer?.remarks}
                                            color={errors.remarks ? 'failure' : 'default'}
                                            helperText={errors.remarks ? errors.remarks.message as string : undefined}
                                        />

                                        <ToggleSwitch
                                            className={'mt-4'}
                                            checked={saveCustomer}
                                            onChange={e => setSaveCustomer(e)}
                                            label={t('customer.save_data')}
                                        />

                                        <hr className={'!my-4'}/>

                                        <Button
                                            className={'flex'}
                                            color={state === 'error' ? 'failure' : 'default'}
                                            size={'lg'}
                                            onClick={handleSubmit(accept)}
                                            disabled={!canAccept(order) || (!isSubmitted && !isValid) || state === 'accepting'}
                                        >
                                            {state === 'init' && <>{t('button.accept_booking')}</>}
                                            {state === 'accepting' && <PacmanLoader size={10} color={'white'}/>}
                                            {state === 'error' && <FaRegFaceSadTear/>}
                                        </Button>
                                    </div>

                                </Card>

                            </div>

                            <Card className={'flex flex-col h-fit basis-1/3'}>
                                <div className={'flex flex-col basis-full p-6'}>
                                    <h1 className={'font-bold text-light-main dark:text-white text-lg text-center'}>
                                        {t('header.your_book')}
                                    </h1>

                                    <hr className={'my-4'}/>

                                    <div className={'py-1 flex flex-row'}>
                                        <p className={'text-gray-300 dark:text-gray-400 font-mono'}>{t('input.arrival')}:&nbsp;</p>
                                        {dateFrom.toLocaleDateString()}
                                    </div>

                                    <div className={'py-1 flex flex-row'}>
                                        <p className={'text-gray-300 dark:text-gray-400 font-mono'}>{t('input.departure')}:&nbsp;</p>
                                        {dateTo.toLocaleDateString()}
                                    </div>

                                    <div className={'py-1 flex flex-row'}>
                                        <p className={'text-gray-300 dark:text-gray-400 font-mono'}>{t('input.nights')}:&nbsp;</p>
                                        {diffInDays(dateFrom, dateTo)}
                                    </div>


                                    <hr className={'my-4'}/>

                                    <div className={'flex flex-col basis-full gap-2'}>

                                        {orderedRooms.length === 0 && (
                                            <p className={'text-center'}>
                                                {t('alert.no_items')}
                                            </p>
                                        )}

                                        {orderedRooms.map((room, index) => (
                                            <Banner className={'bg-gray-200 dark:bg-gray-600'} key={index}>
                                                <div className="flex w-full justify-between">
                                                    <div className="flex items-center m-2 font-bold">
                                                        {room.title[language]}
                                                    </div>
                                                </div>
                                                <div className={'flex flex-col m-2 mt-0'}>
                                                    <div className={'py-1 flex flex-row basis-full justify-between'}>
                                                        <div className={'flex flex-row'}>
                                                            <p className={'flex text-gray-400 dark:text-gray-300 font-mono'}>{t('input.rooms')}:&nbsp;</p>

                                                            <p className={'flex lowercase'}>{room.amount} x {room.price} {t('currency')}</p>
                                                        </div>


                                                        <p className={'flex lowercase'}>{(room.amount * room.price).toFixed(2)} {t('currency')}</p>
                                                    </div>
                                                </div>
                                            </Banner>
                                        ))}
                                    </div>

                                    <hr className={'my-4'}/>

                                    <div className={'py-1 flex flex-row pb-4'}>
                                        <p className={'text-gray-300 dark:text-gray-400 font-mono'}>{t('input.total')}:&nbsp;</p>

                                        <p className={'lowercase'}>{total().toFixed(2)} {t('currency')}</p>
                                    </div>
                                </div>

                            </Card>

                        </div>
                    </div>
                )}

            </div>
        </>
    );
}