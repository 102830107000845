import RoomCardComponent from "../components/RoomCardComponent/RoomCardComponent";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {AppContext} from "../App.context";
import SearchRoomCardComponent from "../components/SearchRoomCard/SearchRoomCard.component";
import MainCarouselComponent from "../components/MainCarousel/MainCarousel.component";
import HotelAdvantages from "../components/HotelAdvantages";
import { useThemeMode } from "flowbite-react";
import { PacmanLoader } from "react-spinners";

export default function MainPage() {
    const {t} = useTranslation();
    const {roomsInfo, language, pricesLoaded} = useContext(AppContext);

    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [show, setShow] = useState(false)
    
    useEffect(() => {
        if (pricesLoaded) {
            setShow(true)
        }
    }, [pricesLoaded, show])

    return (
        <>
            {language === 'en' && (
                <div
                    className={'pt-[80px] md:pt-0 h-full flex flex-col w-full items-center justify-center transition-all duration-500'}>
                    <video className={`${isVideoLoaded ? 'opacity-100' : 'opacity-70'} transition-all duration-500 bg-[url(/public/imgs/preloader.jpg)] bg-cover`}
                           autoPlay={true} loop={false} muted={true} playsInline={true}
                           controls={false} width="100%" onLoadedData={_ => setIsVideoLoaded(true)}
                           onLoadStart={_ => setIsVideoLoaded(false)}>
                        {/*<source src="/video.webm" type="video/webm"/>*/}
                        <source src={`/video_${language}.mp4`} type="video/mp4"/>
                        Sorry, your browser doesn't support videos.
                    </video>
                </div>
            )}

            {language === 'uk' && (
                <div
                    className={'pt-[80px] md:pt-0 h-full flex flex-col w-full items-center justify-center transition-all duration-500'}>
                    <video className={`${isVideoLoaded ? 'opacity-100' : 'opacity-70'} transition-all duration-500 bg-[url(/public/imgs/preloader.jpg)] bg-cover`}
                           autoPlay={true} loop={false} muted={true} playsInline={true}
                           controls={false} width="100%" onLoadedData={_ => setIsVideoLoaded(true)}
                           onLoadStart={_ => setIsVideoLoaded(false)}>
                        {/*<source src="/video.webm" type="video/webm"/>*/}
                        <source src={`/video_${language}.mp4`} type="video/mp4"/>
                        Sorry, your browser doesn't support videos.
                    </video>
                </div>
            )}

            {language === 'ru' && (
                <div
                    className={'pt-[80px] md:pt-0 h-full flex flex-col w-full items-center justify-center transition-all duration-500'}>
                    <video className={`${isVideoLoaded ? 'opacity-100' : 'opacity-70'} transition-all duration-500 bg-[url(/public/imgs/preloader.jpg)] bg-cover`}
                           autoPlay={true} loop={false} muted={true} playsInline={true}
                           controls={false} width="100%" onLoadedData={_ => setIsVideoLoaded(true)}
                           onLoadStart={_ => setIsVideoLoaded(false)}>
                        {/*<source src="/video.webm" type="video/webm"/>*/}
                        <source src={`/video_${language}.mp4`} type="video/mp4"/>
                        Sorry, your browser doesn't support videos.
                    </video>
                </div>
            )}

            <div
                className={'container flex flex-col shadow-xl shadow-black dark:shadow-gray-400 self-center bg-light-container-bg dark:bg-dark-container-bg'}>

                <SearchRoomCardComponent/>

                <h1 id={'rooms'}
                    className={'flex text-3xl text-light-main uppercase font-medium justify-center py-16 dark:text-white'}>
                    {t('header.our_rooms')}
                </h1>

                {/*<div className={`w-20 h-20 ${testDimension}`} />*/}

                <div className={'flex flex-row flex-wrap max-w-full justify-center gap-6'}>

                    {!show && <RoomsSearchLoader/>}

                    {show && roomsInfo.map((room, index) => (
                        <RoomCardComponent key={index} room={room} />
                    ))}
                </div>

                <h1 className={'flex text-3xl text-light-main uppercase font-medium justify-center py-16 dark:text-white text-center'}>
                    {t('header.territory')}
                </h1>

                <MainCarouselComponent />

                <h1 className={'flex text-3xl text-light-main uppercase font-medium justify-center py-16 dark:text-white'}>
                    {t('header.our_advantages')}
                </h1>

                <HotelAdvantages />
            </div>
        </>
    );
}

const RoomsSearchLoader = () => {
    const {computedMode} = useThemeMode();

    return (
        <div className={'flex flex-col basis-full justify-center items-center py-[143px] lg:py-[43px]'}>
            <PacmanLoader size={30} color={computedMode === 'dark' ? 'white' : '#301212'} />
        </div>
    )
}