import axios, {AxiosInstance, AxiosResponse} from "axios";

const MAIN_URL = 'https://my.easyms.co';
const KEY = '24eba502-9984-4437-8c68-2f4a70e21917';

// Available categories
// const availableCategoriesUrl = (key, params) =>
//     urlParams(`${MAIN_URL}/api/reservation/pub/${key}/availableCategories`, params)
//
// // Categories info
// const categoriesUrl = key => `${MAIN_URL}/api/reservation/pub/${key}/categoryDescriptions`
//
// // Prices
// const pricesUrl = (key, params) => urlParams(`${MAIN_URL}/api/reservation/pub/${key}/prices`, params)
//
// // Create order
// const createOrderUrl = key => `${MAIN_URL}/api/reservation/pub/${key}/createOrder`

interface RoomCategory {
    availability: number;
    categoryId: number;
    categoryName: string;
}

export interface CategoryPrice {
    categoryId: number;
    value: number;
}

interface CategoryPricesData {
    prices: CategoryPrice[];
    rateId: number;
    currency: string;
}

export interface OrderCustomer {
    name: string;
    telephone: string;
    email: string;
    remarks?: string;
}

export interface OrderRoom {
    //UTC DATE
    arrival: number;
    //UTC DATE
    departure: number;
    //PRICE
    invoice: number;
    //ROOM CATEGORY
    categoryId: number;
}

export interface Order {
    customer?: OrderCustomer;
    rooms?: OrderRoom[];
}

interface RoomCategoriesResponse extends AxiosResponse<RoomCategory[]> {}
export interface RoomCategoriesPricesResponse extends AxiosResponse<CategoryPricesData> {}

export interface AvailableRoom {
    categoryId: number;
    availability: number;
    price: number;
}

export class ApiBookingService {

    private readonly api: AxiosInstance;

    private static instance?: ApiBookingService;

    private constructor() {
        this.api = axios.create({
            baseURL: MAIN_URL + `/api/reservation/pub/${KEY}`,
        });
    }

    private static _instance(): ApiBookingService {
        if (!this.instance) this.instance = new ApiBookingService();

        return this.instance;
    }

    static async search(start: Date, end: Date, surcharge: number = 19): Promise<AvailableRoom[]> {
        const params = new URLSearchParams();
        params.append('startTime', start.getUTCMilliseconds().toString())
        params.append('endTime', start.getUTCMilliseconds().toString())

        const prices = await this.prices(start, end);
        const rooms: AvailableRoom[] = [];

        if (prices.status === 200 && prices.data.prices.length > 0) {
            const categories = await this.categories(start, end);

            for (const price of prices.data.prices) {
                const category = categories.data.find( c => c.categoryId === price.categoryId);

                if (category) {

                    let roomPrice = Math.floor(price.value - price.value / 100 * surcharge);

                    const room: AvailableRoom = {categoryId: category.categoryId, availability: category.availability, price: roomPrice};

                    rooms.push(room);
                }
            }
        }

        return rooms;
    }

    static async categories(start: Date, end: Date): Promise<RoomCategoriesResponse> {
        const params = new URLSearchParams();
        params.append('startTime', this.dateToUTCString(start))
        params.append('endTime', this.dateToUTCString(end))

        return await this._instance().api.get('/availableCategories', { params: params });
    }

    static async prices(start: Date, end: Date): Promise<RoomCategoriesPricesResponse> {
        const params = new URLSearchParams();
        params.append('startTime', this.dateToUTCString(start))
        params.append('endTime', this.dateToUTCString(end))

        return await this._instance().api.get('/prices', { params: params });
    }

    static async order(order: Order): Promise<AxiosResponse> {
        return await this._instance().api.post('/createOrder', order);
    }

    private static dateToUTCString(date: Date): string {
        return date.getTime().toString();
    }
}