import {NavLink, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import {AppContext} from "../App.context";
import {useTranslation} from "react-i18next";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa6";
import SearchRoomCardComponent from "../components/SearchRoomCard/SearchRoomCard.component";
import RoomCardComponent from "../components/RoomCardComponent/RoomCardComponent";
import {useThemeMode} from "flowbite-react/lib/esm/hooks/use-theme-mode";
import {Button, Carousel} from "flowbite-react";
import ContentBannerComponent from "../components/ContentBanner/ContentBanner.component";

export default function RoomPage() {
    const {id} = useParams();
    const {t} = useTranslation();
    const { computedMode } = useThemeMode();
    const {roomsInfo, language, filterByCategory, showFullScreenImages} = useContext(AppContext);
    const navigate = useNavigate();

    const room = roomsInfo.find( r => r.internalId === id);
    const curRoomIndex = roomsInfo.findIndex(r => r.internalId === id);
    const prevRoomIndex = curRoomIndex > 0 ? curRoomIndex - 1 : roomsInfo.length - 1;
    const nextRoomIndex = curRoomIndex < (roomsInfo.length - 1) ? curRoomIndex + 1 : 0;
    const prevRoom = roomsInfo.at(prevRoomIndex);
    const nextRoom = roomsInfo.at(nextRoomIndex);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [id]);

    const bookRoom = (id?: string): void => {
        if (id) {
            filterByCategory(id);
            navigate('/book', {unstable_viewTransition: true})
        }
    }

    return (
        <>
            <ContentBannerComponent />

            <div
                className={'container flex flex-col shadow-xl shadow-black dark:shadow-gray-400 self-center bg-light-container-bg dark:bg-dark-container-bg dark:text-white'}>

                <div className={'flex flex-row w-full lg:w-4/5 self-center py-6 sm:py-12 md:py-16'}>

                    <NavLink to={`/rooms/${prevRoom?.internalId}`} unstable_viewTransition={true}
                             className={'hidden sm:flex flex-row flex-grow-0 text-xs md:text-sm lg:text-lg w-4/12 text-center gap-2 pl-2 items-center'}>
                        <FaAngleLeft className={'text-light-main dark:text-white h-5 w-5 md:h-10 md:w-10 '}/>

                        <div className={'flex flex-col basis-full'}>
                            <p>{prevRoom?.title[language]}</p>
                            <p className={`${!prevRoom?.available ? 'text-gray-400' : ''}`}>{prevRoom?.curPrice ? prevRoom.curPrice : prevRoom?.price} {t('currency')}</p>
                        </div>
                        
                    </NavLink>

                    <div className="flex flex-col flex-grow-1 basis-full">
                        <div
                            className={'flex flex-col flex-grow-1 basis-full text-center items-center text-lg md:text-2xl lg:text-3xl justify-center'}>
                            {room ? room.title[language] : '-'}
                        </div>
                        <div
                            className={`${'flex flex-col flex-grow-1 basis-full text-center items-center text-lg md:text-2xl lg:text-3xl justify-center' + (!room?.available ? ' text-gray-400' : '')}`}>    
                            {room?.curPrice ? room.curPrice : room?.price} {t('currency')}
                            {!room?.available && (
                                <sub className="text-xs text-gray-400 p-0 m-0 mt-[-6pt]">({t('room.price_at')} {room?.priceDate})</sub>
                            )}
                        </div>
                    </div>
                    
                    

                    <NavLink to={`/rooms/${nextRoom?.internalId}`} unstable_viewTransition={true}
                             className={'hidden sm:flex text-xs md:text-sm lg:text-lg flex-row flex-grow-0 w-4/12 text-center gap-2 pr-2 items-center'}>
                        <div className={`${'flex flex-col basis-full'}`}>
                            <p>{nextRoom?.title[language]}</p>
                            <p className={`${!nextRoom?.available ? 'text-gray-400' : ''}`}>{nextRoom?.curPrice ? nextRoom.curPrice : nextRoom?.price} {t('currency')}</p>
                        </div>

                        <FaAngleRight className={'text-light-main dark:text-white h-5 w-5 md:h-10 md:w-10'}/>
                    </NavLink>

                </div>

                <div className={'flex flex-col lg:flex-row w-full lg:w-4/5 self-center'}>

                    <div className={'flex flex-col w-11/12 self-center lg:self-start lg:w-2/3 items-center'}>
                        {/*<div className={'flex flex-col transition-all duration-1000 '} onScroll={ e => e.stopPropagation() } onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>*/}
                        {/*    <img src={room?.gallery[curImg]} className={'rounded-xl'} alt={room?.title[language]}/>*/}
                        {/*</div>*/}


                        <div className="flex flex-col w-full h-56 sm:h-96 md:h-[450px] lg:h-[350px] xl:h-[550px]">
                            <Carousel draggable={true} pauseOnHover={true}>

                                {room && room.gallery.map((src, index) => (
                                    <img src={src} key={index} alt={src} className={'rounded-xl'} onClick={ _ => showFullScreenImages(room.gallery, index)}/>
                                ))}

                            </Carousel>
                        </div>


                        {/*<HorizontalScroll className={'flex flex-row gap-2 pt-1'}>*/}

                        {/*    {room && room.gallery.map((src, index) => (*/}
                        {/*        <img*/}
                        {/*            src={src}*/}
                        {/*            key={index}*/}
                        {/*            className={`w-[80px] md:w-[110px] xl:w-[128px] ${curImg === index ? 'py-1' : 'py-2'} transition-all duration-300`}*/}
                        {/*            onClick={e => changeImage(e, index)}*/}
                        {/*            alt={src}*/}
                        {/*        />*/}
                        {/*    ))}*/}
                        {/*</HorizontalScroll>*/}
                    </div>

                    <div className={'flex flex-col w-11/12 self-center lg:self-start lg:w-1/3 lg:pl-4'}>
                        <h4 className={'font-bold text-light-main dark:text-white'}>
                            {t('room.capacity')}:
                        </h4>

                        <div className={'flex flex-row items-center gap-4 py-4 dark:text-white'}>
                            <img src={computedMode === 'dark' ? '/icons/light/People.png' : '/icons/People.png'}
                                 alt={'capacity'} className={'w-[40px]'}/>

                            {room?.capacity[language]}
                        </div>

                        <h4 className={'font-bold text-light-main dark:text-white'}>
                            {t('room.advantage')}:
                        </h4>

                        <div className={'flex flex-row items-center gap-4 py-4 dark:text-white'}>
                            {room?.description[language]}
                        </div>

                        {room?.facilities.map((icon, index) => (
                            <div className={'flex flex-row w-full'} key={index}>
                                <div className={'self-center w-[40px]'}>
                                    <img src={computedMode !== 'dark' ? icon.icon.replaceAll('light/', '') : icon.icon}
                                         alt={'icon'} width={40}/>
                                </div>

                                <p className={'py-1 px-2 text-sm sm:text-lg dark:text-white'}>{icon.title[language]}</p>
                            </div>
                        ))}

                        <Button size={'xl'} color={'default'} className={'w-full !text-xs sm:!text-sm uppercase mt-2'}
                                onClick={_ => bookRoom(room?.externalId)}>
                            {t('button.book_room')}
                        </Button>
                    </div>
                </div>

                <h1
                    className={'flex text-3xl text-light-main uppercase font-medium justify-center py-16 dark:text-white'}>
                    {t('header.other_rooms')}
                </h1>

                <div className={'flex flex-row flex-wrap max-w-full justify-center gap-12 pb-12'}>
                    {roomsInfo.filter(r => r.internalId !== id).map((room, index) => (
                        <RoomCardComponent key={index} room={room}/>
                    ))}
                </div>

                <SearchRoomCardComponent top={false}/>
            </div>

        </>
    );
}