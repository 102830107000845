import ContentBannerComponent from "../components/ContentBanner/ContentBanner.component";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Alert, Banner, Button, Card, Datepicker, Select} from "flowbite-react";
import {useTranslation} from "react-i18next";
import {AppContext, AppOrderRoom} from "../App.context";
import {HiX} from "react-icons/hi";
import {AppRoom, appData} from "../app.data";
import {useThemeMode} from "flowbite-react/lib/esm/hooks/use-theme-mode";
import {ApiBookingService} from "../lib/api-booking.service";
import {PacmanLoader} from "react-spinners";
import {FaInfo, FaRegFaceSadTear, FaRegThumbsUp} from "react-icons/fa6";
import {useLocalStorage} from "../lib/local-storage.hook";
import {IoClose} from "react-icons/io5";
import {PiSealWarningDuotone} from "react-icons/pi";
import {convertArrivalDate, convertDepartureDate, day, diffInDays} from "../lib/utils";
import {useNavigate} from "react-router-dom";

interface OrderedRoom extends AppRoom {
    amount: number;
    orderedPrice?: number;
}

export default function BookingPage() {
    const {t} = useTranslation();
    const {language, roomsInfo, filteredCategories, removeCategory, order, changeOrderRooms, changeArrivalDate, changeDepartureDate, showFullScreenImages, surcharge, pricesLoaded} = useContext(AppContext);
    const navigate = useNavigate();
    const { computedMode } = useThemeMode();
    const [showInfoAlert, setShowInfoAlert] = useLocalStorage('bookingInfoAlert', true);
    const [orderedRooms, setOrderedRooms] = useState(order.rooms.map( r => {
        const _room = roomsInfo.find( _r => _r.externalId === r.categoryId.toString()) as OrderedRoom | undefined;
        if (_room) {
            _room.amount = r.amount;
            _room.orderedPrice = r.price;

            return _room;
        }

        return undefined;
    }).filter(r => r !== undefined) as OrderedRoom[]);
    const [availableRooms, setAvailableRooms] = useState([] as AppRoom[]);
    const minFrom = new Date();
    const [dateFrom, setDateFrom] = useState(convertArrivalDate(new Date(order.arrivalDate)));
    const [minTo, setMinTo] = useState(new Date(dateFrom.getTime()  + day));
    const [dateTo, setDateTo] = useState(convertDepartureDate(new Date(order.departureDate)));
    const [isSearching, setSearching] = useState(false);
    const isSearched = useRef(false);
    const isFetched = useRef(false);


    const total = () => {
        let sum = 0;
        orderedRooms.forEach( r => sum+= r.amount * (r.orderedPrice ?? 0));
        return sum;
    }

    const roomData = (externalId: string): AppRoom | undefined => {
        return appData.find(r => r.externalId === externalId);
    }

    const filteredResult = (data: AppRoom[], filterCategories: string[]) => {
        return data.filter( r => filterCategories.length > 0 ?  filterCategories.includes(r.externalId.toString()) : true);
    }

    const restAvailable = (data: AppRoom[], filterCategories: string[]) => {
        return data.filter( r => filterCategories.length > 0 ?  !filterCategories.includes(r.externalId.toString()) : true);
    }

    const search = useCallback(async (filterCategories: string[]) => {
        setSearching(true);
        if (!isSearched.current) isSearched.current = true;

        try {
            const data = await ApiBookingService.search(dateFrom, dateTo, surcharge);
            const _rooms: AppRoom[] = [];

            setAvailableRooms([]);
            if (data.length > 0) {
                for (const availableRoom of data) {
                    const _room = Object.create(roomsInfo.find( r => r.externalId === availableRoom.categoryId.toString()) as object) as AppRoom | undefined;

                    if (_room) {

                        const available = {
                            externalId: _room.externalId,
                            internalId: _room.internalId,
                            title: _room.title,
                            image: _room.image,
                            description: _room.description,
                            capacity: _room.capacity,
                            gallery: _room.gallery,
                            facilities: _room.facilities,
                            curPrice: availableRoom.price,
                            availability: availableRoom.availability
                        } as AppRoom;
                        _rooms.push(available);
                    }
                }

                setOrderedRooms(p => {
                    const list = p.map( o => {
                        const r = _rooms.find( _r => _r.externalId === o.externalId);
                        if (r) o.orderedPrice = r.curPrice ?? 0;

                        return o;
                    });

                    changeOrderRooms(list.map( r => {
                        return { categoryId: r.externalId, price: r.orderedPrice, amount: r.amount } as AppOrderRoom;
                    }));
                    changeArrivalDate(dateFrom);
                    changeDepartureDate(dateTo);

                    return list;
                });
            }

            setSearching(false);
            setAvailableRooms(_rooms);
        } catch (e) {
            console.log(e);
            setSearching(false);
            setAvailableRooms([]);
        }
    }, [changeArrivalDate, changeDepartureDate, changeOrderRooms, dateFrom, dateTo, roomsInfo])

    useEffect(() => {
        if (!isFetched.current && pricesLoaded) {
            isFetched.current = true;
            void search(filteredCategories);

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [isFetched, search, filteredCategories]);

    const changeDateFrom = (date: Date) => {
        const minToDate = new Date(date.getTime() + day);

        setDateFrom(convertArrivalDate(date));
        setMinTo(minToDate)
        changeArrivalDate(convertArrivalDate(date));


        if (dateTo < minToDate) setDateTo(convertDepartureDate(minToDate));
    }

    const changeDateTo = (date: Date) => {
        setDateTo(convertDepartureDate(date));
    }

    const closeAlert = () => {
        setShowInfoAlert(false);
    }

    const orderedRoom = (room: AppRoom, bookedRooms: OrderedRoom[]): OrderedRoom | undefined => {
        return bookedRooms.find( b => b.externalId === room.externalId);
    }

    const isOrdered = (room: AppRoom, _orderedRooms: OrderedRoom[]): boolean => {
        if (!room || !_orderedRooms || _orderedRooms.length === 0) return false;

        const ordered = orderedRoom(room, _orderedRooms);
        if (!ordered) return false;

        return ordered.amount === room.availability;
    }

    const orderedAmount = (room: AppRoom, orderedRooms: OrderedRoom[]): number => {
        if (!room || !orderedRooms || orderedRooms.length === 0) return 0;

        const ordered = orderedRoom(room, orderedRooms);
        return ordered ? ordered.amount : 0;
    }

    const roomAmountsElements = (amount: number, price: number): JSX.Element[] => {
        const options: JSX.Element[] = [];
        for (let i = 1; i <= amount; i++) {
            options.push(
                <option key={i} className={'text-center'} value={i}>
                    {i} x {price} ({price * i} {t('currency')})
                </option>
            )
        }

        return options;
    }

    const orderRoom = (room: AppRoom, ordered: OrderedRoom[]) => {
        const order: OrderedRoom = room as OrderedRoom;
        order.orderedPrice = room.curPrice
        order.amount = 1;

        const oR = orderedRoom(room, ordered);
        if (!oR) setOrderedRooms(p => {
            const list = [...p, order];
            changeOrderRooms(list.map( r => {
                return { categoryId: r.externalId, price: r.orderedPrice, amount: r.amount } as AppOrderRoom;
            }));
            changeArrivalDate(dateFrom);
            changeDepartureDate(dateTo);

            return list;
        });
    };

    const cancelOrderRoom = (room: AppRoom, ordered: OrderedRoom[]) => {
        setOrderedRooms( prev => {
            const list = prev.filter( r => r.externalId !== room.externalId);
            changeOrderRooms(list.map( r => {
                return { categoryId: r.externalId, price: r.orderedPrice, amount: r.amount } as AppOrderRoom;
            }));
            changeArrivalDate(dateFrom);
            changeDepartureDate(dateTo);

            return list;
        });
    };

    const isSelectOrderAmount = (room: AppRoom, ordered: OrderedRoom[]): boolean => {
        if (!room || !ordered || ordered.length === 0) return false;

        const _orderedRoom = orderedRoom(room, ordered);

        return _orderedRoom !== undefined && room.availability > 1;
    }

    const changeOrderAmount = (amount: number, room: AppRoom, ordered: OrderedRoom[]) => {
        if (room && ordered && ordered.length > 0) setOrderedRooms(prev => {
            const list = prev.map( r => {
                if (r.externalId !== room.externalId) return r;

                r.amount = amount;
                return  r;
            });
            changeOrderRooms(list.map( r => {
                return { categoryId: r.externalId, price: r.orderedPrice, amount: r.amount } as AppOrderRoom;
            }));
            changeArrivalDate(dateFrom);
            changeDepartureDate(dateTo);

            return list;
        })
    }

    const canContinue = (isSearch: boolean, available: AppRoom[], ordered: OrderedRoom[]): boolean => {
        if (isSearch || ordered.length === 0) return false;

        let isAllAvailable = true;
        for (const _ordered of ordered) {
            const _available = available.find( r => r.externalId === _ordered.externalId);
            if (!_available) {
                isAllAvailable = false;
                break;
            }
        }

        return isAllAvailable;
    }

    const continueBooking = () => {
        navigate('/book/accept', {unstable_viewTransition: true});
    }

    const removeFilter = async (id: string) => {
        removeCategory(id);

        await search(filteredCategories.filter(i => i !== id));
    }

    return (
        <>
            <ContentBannerComponent/>

            <div
                className={'container flex flex-col shadow-xl shadow-black dark:shadow-gray-400 self-center bg-light-container-bg dark:bg-dark-container-bg dark:text-white'}>

                <div className={'flex flex-row w-full lg:w-4/5 self-center py-6 sm:py-12 md:py-16'}>
                    <div
                        className={'flex flex-col flex-grow-1 basis-full text-center items-center text-lg md:text-2xl lg:text-3xl justify-center'}>
                        {t('header.search_rooms')}
                    </div>
                </div>

                {showInfoAlert && (
                    <div className={'flex flex-col w-full lg:w-4/5 self-center py-6 px-6 lg:px-0'}>
                        <Alert
                            color={'blue'}
                            icon={FaInfo}
                            onDismiss={closeAlert}
                            rounded
                        >
                            {t('alert.change_price_info')}
                        </Alert>

                    </div>
                )}

                <div className={'flex flex-row w-full lg:w-4/5 self-center'}>

                    {isSearching && <RoomsSearchLoader/>}

                    {!isSearching && (
                        <div className={'flex flex-col basis-full gap-6 py-6 lg:flex-row'}>
                            <div className={'flex flex-col basis-1/3 px-6 lg:px-0'}>
                                <div className={'py-2'}>
                                    {t('input.date_arrival')}
                                </div>
                                <Datepicker
                                    showTodayButton={false}
                                    showClearButton={false}
                                    sizing={'lg'}
                                    className={''}
                                    language={language}
                                    title={t('input.date_arrival')}
                                    onSelectedDateChanged={date => changeDateFrom(date)}
                                    minDate={minFrom}
                                    defaultDate={dateFrom}
                                    value={dateFrom.toLocaleDateString()}
                                />
                            </div>
                            <div className={'flex flex-col basis-1/3 px-6 lg:px-0'}>
                                <div className={'py-2'}>
                                    {t('input.date_departure')}
                                </div>
                                <Datepicker
                                    sizing={'lg'}
                                    title={t('input.date_departure')}
                                    showTodayButton={false}
                                    showClearButton={false}
                                    language={language}
                                    minDate={minTo}
                                    value={dateTo.toLocaleDateString()}
                                    defaultDate={dateTo}
                                    onSelectedDateChanged={date => changeDateTo(date)}
                                />
                            </div>
                            <div className={'flex flex-col justify-end basis-1/3 px-6 lg:px-0'}>
                                <Button size={'xl'} color={'default'} className={'w-full'}
                                        onClick={_ => search(filteredCategories)}>{t('button.find_rooms')}</Button>
                            </div>
                        </div>
                    )}

                </div>

                {filteredCategories.length > 0 && (
                    <div className={'flex flex-row w-11/12 lg:w-4/5 self-center gap-2 items-center flex-wrap'}>
                        <p>{t('alert.filter')}:</p>

                        {filteredCategories.map((id, index) => (
                            <Banner className={'bg-gray-200 dark:bg-gray-600'} key={index}>

                                <div className="flex w-full justify-between">
                                    <div className="flex items-center m-2 font-bold">
                                        {roomsInfo.find(r => r.externalId === id)?.title[language]}
                                    </div>
                                    <Banner.CollapseButton onClick={_ => removeFilter(id)}
                                                           color={computedMode} size={'xs'}
                                                           className="border-0 dark:bg-transparent bg-transparent m-2">
                                        <HiX className="h-4 w-4"/>
                                    </Banner.CollapseButton>
                                </div>


                            </Banner>
                        ))}
                    </div>
                )}

                <div className={'flex flex-row w-11/12 lg:w-4/5 self-center'}>
                    <div className={'flex flex-col lg:flex-row basis-full gap-6 py-6 '}>

                        <div className={'flex flex-col basis-2/3 gap-4'}>

                            {!isSearched.current && (
                                <div className={'flex flex-col w-full'}>
                                    <Alert
                                        color={'info'}
                                        icon={FaRegThumbsUp}
                                        rounded
                                    >
                                        {t('alert.choose_dates')}
                                    </Alert>

                                </div>
                            )}

                            {!isSearching && isSearched.current && filteredCategories.length > 0 && filteredResult(availableRooms, filteredCategories).length === 0 && restAvailable(availableRooms, filteredCategories).length > 0 &&  (
                                <div className={'flex flex-col w-full'}>
                                    <Alert
                                        color={'info'}
                                        icon={FaRegFaceSadTear}
                                        rounded
                                    >
                                        {t('alert.not_found_filtered', {rooms: filteredCategories.map( c => roomData(c) ? roomData(c)?.title[language] : '-' ).join(', ')})}
                                    </Alert>

                                </div>
                            )}

                            {filteredCategories.length > 0 && filteredResult(availableRooms, filteredCategories).map((room, index) => (
                                <Card className={'flex flex-col'} key={index}>
                                    <div className={'flex flex-col sm:flex-row basis-full'}>

                                        <img src={room.image}
                                             className={'w-full p-6 pb-0 sm:w-1/2 sm:m-6 sm:p-0 sm:h-[300px]'}
                                             onClick={ _ => showFullScreenImages(room.gallery, 0)}
                                             alt={room?.title[language]}/>

                                        <div
                                            className={'flex flex-col basis-full sm:basis-1/2 p-6 sm:p-0 sm:my-6 sm:mr-6'}>
                                            <h4 className={'font-bold text-light-main dark:text-white'}>
                                                {room.title[language]}
                                            </h4>

                                            <p className={'pt-2 text-gray-300 dark:text-gray-400 font-mono'}>{t('room.available')}: {room.availability}</p>

                                            <p className={'pt-2 text-sm'}>{room.description[language]}</p>

                                            <p className={'pt-4 text-xl'}>{room?.curPrice?.toFixed(2)} {t('currency')}</p>

                                            {!isOrdered(room, orderedRooms) && !isSelectOrderAmount(room, orderedRooms) && (
                                                <div className={'flex flex-col basis-full justify-end'}>
                                                    <Button
                                                        disabled={isSearching}
                                                        color={'default'}
                                                        size={'lg'}
                                                        onClick={ _ => orderRoom(room, orderedRooms)}
                                                    >
                                                        {t('button.book_room')}
                                                    </Button>
                                                </div>
                                            )}

                                            {isOrdered(room, orderedRooms) && !isSelectOrderAmount(room, orderedRooms) && (
                                                <div className={'flex flex-col basis-full justify-end'}>
                                                    <Button color={'default'} size={'lg'} onClick={ _ => cancelOrderRoom(room, orderedRooms)}>
                                                        {t('button.cancel_order')}
                                                    </Button>
                                                </div>
                                            )}

                                            {isSelectOrderAmount(room, orderedRooms) && (
                                                <div className={'flex flex-col basis-full justify-end'}>
                                                    <p className={'self-center text-gray-300 dark:text-gray-400 font-mono text-sm'}>
                                                        {t('room.set_amount')}
                                                    </p>

                                                    <div className={'flex flex-row w-full gap-2 justify-between'}>

                                                        <Select
                                                            disabled={isSearching}
                                                            className={'w-10/12'}
                                                            defaultValue={orderedAmount(room, orderedRooms)}
                                                            onChange={e => changeOrderAmount(parseInt(e.target.value ?? 1), room, orderedRooms)}
                                                            sizing={'md'}>

                                                            {roomAmountsElements(room.availability, room.curPrice ?? 0).map(e => e)}

                                                        </Select>

                                                        <Button color={'default'} className={'w-10 rounded-xl'} onClick={_ => cancelOrderRoom(room, orderedRooms)}>
                                                            <IoClose />
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </Card>
                            ))}

                            {filteredCategories.length > 0 && isSearched.current && restAvailable(availableRooms, filteredCategories).length > 0 && (
                                <div className={'flex flex-col w-full py-2'}>
                                    <p>{t('alert.other_variants')}:</p>
                                </div>
                            )}

                            {!isSearching && isSearched.current && filteredResult(availableRooms, filteredCategories).length === 0 && restAvailable(availableRooms, filteredCategories).length === 0 && (
                                <div className={'flex flex-col w-full'}>
                                    <Alert
                                        color={'info'}
                                        icon={FaRegFaceSadTear}
                                        rounded
                                    >
                                        {t('alert.not_found')}
                                    </Alert>

                                </div>
                            )}

                            {restAvailable(availableRooms, filteredCategories).map((room, index) => (
                                <Card className={'flex flex-col'} key={index}>
                                    <div className={'flex flex-col sm:flex-row basis-full'}>

                                        <img src={room.image}
                                             className={'w-full p-6 pb-0 sm:w-1/2 sm:m-6 sm:p-0 sm:h-[300px]'}
                                             onClick={ _ => showFullScreenImages(room.gallery, 0)}
                                             alt={room?.title[language]}/>

                                        <div
                                            className={'flex flex-col basis-full sm:basis-1/2 p-6 sm:p-0 sm:my-6 sm:mr-6'}>
                                            <h4 className={'font-bold text-light-main dark:text-white'}>
                                                {room.title[language]}
                                            </h4>

                                            <p className={'pt-2 text-gray-300 dark:text-gray-400 font-mono'}>{t('room.available')}: {room.availability}</p>

                                            <p className={'pt-2 text-sm'}>{room.description[language]}</p>

                                            <p className={'pt-4 text-xl'}>{room?.curPrice?.toFixed(2)} {t('currency')}</p>

                                            {!isOrdered(room, orderedRooms) && !isSelectOrderAmount(room, orderedRooms) && (
                                                <div className={'flex flex-col basis-full justify-end'}>
                                                    <Button
                                                        disabled={isSearching}
                                                        color={'default'}
                                                        size={'lg'}
                                                        onClick={ _ => orderRoom(room, orderedRooms)}
                                                    >
                                                        {t('button.book_room')}
                                                    </Button>
                                                </div>
                                            )}

                                            {isOrdered(room, orderedRooms) && !isSelectOrderAmount(room, orderedRooms) && (
                                                <div className={'flex flex-col basis-full justify-end'}>
                                                    <Button color={'default'} size={'lg'} onClick={ _ => cancelOrderRoom(room, orderedRooms)}>
                                                        {t('button.cancel_order')}
                                                    </Button>
                                                </div>
                                            )}

                                            {isSelectOrderAmount(room, orderedRooms) && (
                                                <div className={'flex flex-col basis-full justify-end'}>
                                                    <p className={'self-center text-gray-300 dark:text-gray-400 font-mono text-sm'}>
                                                        {t('room.set_amount')}
                                                    </p>

                                                    <div className={'flex flex-row w-full gap-2 justify-between'}>

                                                        <Select
                                                            disabled={isSearching}
                                                            className={'w-10/12'}
                                                            defaultValue={orderedAmount(room, orderedRooms)}
                                                            onChange={e => changeOrderAmount(parseInt(e.target.value ?? 1), room, orderedRooms)}
                                                            sizing={'md'}>

                                                            {roomAmountsElements(room.availability, room.curPrice ?? 0).map(e => e)}

                                                        </Select>

                                                        <Button color={'default'} className={'w-10 rounded-xl'} onClick={_ => cancelOrderRoom(room, orderedRooms)}>
                                                            <IoClose />
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </Card>
                            ))}

                        </div>

                        <Card className={'flex flex-col h-fit basis-1/3'}>
                            <div className={'flex flex-col basis-full p-6'}>
                                <h1 className={'font-bold text-light-main dark:text-white text-lg text-center'}>
                                    {t('header.your_book')}
                                </h1>

                                <hr className={'my-4'}/>

                                <div className={'py-1 flex flex-row'}>
                                    <p className={'text-gray-300 dark:text-gray-400 font-mono'}>{t('input.arrival')}:&nbsp;</p>
                                    {dateFrom.toLocaleDateString()}
                                </div>

                                <div className={'py-1 flex flex-row'}>
                                    <p className={'text-gray-300 dark:text-gray-400 font-mono'}>{t('input.departure')}:&nbsp;</p>
                                    {dateTo.toLocaleDateString()}
                                </div>

                                <div className={'py-1 flex flex-row'}>
                                    <p className={'text-gray-300 dark:text-gray-400 font-mono'}>{t('input.nights')}:&nbsp;</p>
                                    {diffInDays(dateFrom, dateTo)}
                                </div>


                                <hr className={'my-4'}/>

                                <div className={'flex flex-col basis-full gap-2'}>

                                    {orderedRooms.length === 0 && (
                                        <p className={'text-center'}>
                                            {t('alert.no_items')}
                                        </p>
                                    )}

                                    {orderedRooms.map((room, index) => (
                                        <Banner className={'bg-gray-200 dark:bg-gray-600'} key={index}>
                                            {!isSearching && !availableRooms.find( r => r.externalId === room.externalId) && (
                                                <Alert
                                                    icon={PiSealWarningDuotone}
                                                    color={'warning'}
                                                    rounded={false}
                                                >
                                                    {t('alert.not_available')}
                                                </Alert>
                                            )}

                                            <div className="flex w-full justify-between">
                                                <div className="flex items-center m-2 font-bold">
                                                    {room.title[language]}
                                                </div>
                                                <Banner.CollapseButton onClick={e => cancelOrderRoom(room, orderedRooms)}
                                                                       color={computedMode} size={'xs'}
                                                                       className="border-0 dark:bg-transparent bg-transparent m-2">
                                                    <HiX className="h-4 w-4"/>
                                                </Banner.CollapseButton>
                                            </div>
                                            <div className={'flex flex-col m-2 mt-0'}>
                                                <div className={'py-1 flex flex-row basis-full justify-between'}>
                                                    <div className={'flex flex-row'}>
                                                        <p className={'flex text-gray-400 dark:text-gray-300 font-mono'}>{t('input.rooms')}:&nbsp;</p>

                                                        <p className={'flex lowercase'}>{room.amount} x {room.orderedPrice} {t('currency')}</p>
                                                    </div>


                                                    <p className={'flex lowercase'}>{(room.amount * (room.orderedPrice ?? 0)).toFixed(2)} {t('currency')}</p>
                                                </div>
                                            </div>
                                        </Banner>
                                    ))}
                                </div>

                                <hr className={'my-4'}/>

                                <div className={'py-1 flex flex-row pb-4'}>
                                    <p className={'text-gray-300 dark:text-gray-400 font-mono'}>{t('input.total')}:&nbsp;</p>

                                    <p className={'lowercase'}>{total().toFixed(2)} {t('currency')}</p>
                                </div>

                                <Button
                                    color={'default'}
                                    size={'lg'}
                                    disabled={!canContinue(isSearching, availableRooms, orderedRooms)}
                                    onClick={continueBooking}
                                >
                                    {t('button.continue')}
                                </Button>
                            </div>

                        </Card>

                    </div>
                </div>

            </div>
        </>
    );
}

const RoomsSearchLoader = () => {
    const {computedMode} = useThemeMode();

    return (
        <div className={'flex flex-col basis-full justify-center items-center py-[143px] lg:py-[43px]'}>
            <PacmanLoader size={30} color={computedMode === 'dark' ? 'white' : '#301212'} />
        </div>
    )
}